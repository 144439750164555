import { useState } from "react";
import { X, Upload, Video, CheckCircle, Sparkles, AlertCircle } from "lucide-react";
import { uploadPromoVideo } from "../../Services/ApiEnpoints";

export default function VideoUploadModal({ isOpen, onClose, user_id }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        if (file) {
            // Validate file type & size (max 50MB)
            const allowedTypes = ["video/mp4", "video/quicktime"];
            if (!allowedTypes.includes(file.type)) {
                setError("Invalid file type. Please upload an MP4 or MOV file.");
                setSelectedFile(null);
                return;
            }
            if (file.size > 100 * 1024 * 1024) { // 50MB limit
                setError("File size exceeds 100MB. Please choose a smaller file.");
                setSelectedFile(null);
                return;
            }

            setSelectedFile(file);
            setError(null); // Clear previous errors
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setError("Please select a file first.");
            return;
        }

        setUploading(true);
        setError(null);
        setSuccessMessage("");

        try {
            const response = await uploadPromoVideo(selectedFile, user_id);
            setSuccessMessage("Video uploaded successfully!");
            console.log("Upload response:", response);
        } catch (error) {
            setError("Upload failed. Please try again.");
        } finally {
            setUploading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 backdrop-blur-sm">
            {/* Backdrop with subtle scale animation */}
            <div  onClick={onClose} className="fixed inset-0 bg-black/30 flex items-center justify-center p-5 transition-all duration-300">
                {/* Modal Container */}
                <div
                    className="bg-white p-1 rounded-3xl shadow-2xl w-full max-w-md transform transition-all duration-300 overflow-hidden"
                    style={{
                        animation: 'modal-enter 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* Header */}
                    <div className="flex justify-between items-center px-6 py-5 border-b">
                        <div className="flex items-center gap-3">
                            <div className="p-2 bg-indigo-50 rounded-lg">
                                <Video className="h-6 w-6 text-indigo-600" />
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-gray-900">30s Self-Promo Video</h3>
                                <p className="text-sm text-gray-500">First impression for recruiters</p>
                            </div>
                        </div>
                        <button
                            onClick={onClose}
                            className="p-2 hover:bg-gray-50 rounded-lg transition-colors"
                            aria-label="Close"
                        >
                            <X className="h-5 w-5 text-gray-500" />
                        </button>
                    </div>

                    {/* Content */}
                    <div className="p-6 space-y-6">
                        {/* Guidelines */}
                        <div className="space-y-3 text-sm">
                            <div className="flex items-center gap-3 text-gray-600">
                                <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                                <span>30-second vertical video (9:16 ratio)</span>
                            </div>
                            <div className="flex items-center gap-3 text-gray-600">
                                <Sparkles className="h-5 w-5 text-yellow-500 flex-shrink-0" />
                                <span>Show personality & career aspirations</span>
                            </div>
                            <div className="flex items-center gap-3 text-gray-600">
                                <AlertCircle className="h-5 w-5 text-indigo-500 flex-shrink-0" />
                                <span>MP4 or MOV, max 50MB</span>
                            </div>
                        </div>

                        {/* Upload Section */}
                        <div className="group relative">
                            <label
                                htmlFor="video-upload"
                                className={`flex flex-col items-center justify-center w-full p-6 border-2 border-dashed rounded-xl cursor-pointer transition-all
                                    ${selectedFile
                                        ? 'border-indigo-100 bg-indigo-50'
                                        : 'border-gray-200 hover:border-indigo-300 bg-gray-50/50'}`}
                            >
                                <div className="flex flex-col items-center space-y-2">
                                    <div className="p-3 bg-white rounded-full shadow-sm border">
                                        <Upload className={`h-6 w-6 transition-colors ${selectedFile ? 'text-indigo-600' : 'text-gray-400'}`} />
                                    </div>
                                    <div className="text-center">
                                        {selectedFile ? (
                                            <p className="font-medium text-indigo-600">{selectedFile.name}</p>
                                        ) : (
                                            <p className="font-medium text-gray-600">
                                                Drag & drop or <span className="text-indigo-600">browse files</span>
                                            </p>
                                        )}
                                        <p className="text-xs text-gray-400 mt-1">Max 50MB</p>
                                    </div>
                                </div>
                            </label>
                            <input
                                id="video-upload"
                                type="file"
                                className="hidden"
                                accept="video/mp4,video/quicktime"
                                onChange={handleFileChange}
                            />
                        </div>

                        {/* Error Message */}
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                        {successMessage && <p className="text-green-500 text-sm">{successMessage}</p>}
                    </div>

                    {/* Footer Actions */}
                    <div className="px-6 py-4 bg-gray-50 flex justify-end gap-3">
                        <button
                            onClick={onClose}
                            className="px-5 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleUpload}
                            className={`px-5 py-2 text-sm font-medium text-white rounded-full transition-all
                                ${selectedFile && !uploading
                                    ? 'bg-indigo-600 hover:bg-indigo-700 shadow-md'
                                    : 'bg-gray-300 cursor-not-allowed'}`}
                            disabled={!selectedFile || uploading}
                        >
                            {uploading ? "Uploading..." : "Upload Video"}
                        </button>
                    </div>
                </div>
            </div>

            <style jsx>{`
                @keyframes modal-enter {
                    0% {
                        opacity: 0;
                        transform: translateY(20px) scale(0.98);
                    }
                    100% {
                        opacity: 1;
                        transform: translateY(0) scale(1);
                    }
                }
            `}</style>
        </div>
    );
}
