import React, { useEffect } from "react";
import { X, AlertTriangle, Clock, FileText, Globe, Shield, Briefcase,Rocket } from "lucide-react";

const InterviewGuideModal = ({ isOpen, onClose, onStart }) => {
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
    };
    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-900/40 backdrop-blur-sm flex items-center justify-center p-4 z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-xl p-6 max-w-xl w-full shadow-xl relative transform transition-all duration-300"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors p-1 hover:bg-gray-100 rounded-md"
        >
          <X size={20} className="stroke-[1.5]" />
        </button>

        <div className="flex flex-col items-center text-center space-y-5">
          <div className="p-4 bg-indigo-100/80 rounded-full mb-2">
            <Rocket className="w-10 h-10 text-indigo-600" strokeWidth={1.5} />
          </div>

          <div className="space-y-4 w-full">
            <h3 className="text-xl font-semibold text-gray-900 mb-1">AI-Powered Mock Interview</h3>

            <div className="text-gray-600 text-sm space-y-4 text-left ml-3">
              {[
                { Icon: Clock, text: "15-minute assessment analyzing your resume-based skills" },
                { Icon: Briefcase, text: "Required for profile publishing on Talent Hub" },
                { Icon: Shield, text: "Results shared with recruiters to showcase your expertise" },
              ].map((item, idx) => (
                <div key={idx} className="flex items-start gap-3">
                  <item.Icon className="w-5 h-5 text-indigo-600 flex-shrink-0 mt-0.5" />
                  <span className="leading-snug">{item.text}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Warning Banner */}
          <div className="w-full bg-indigo-50 p-3 rounded-lg flex gap-2 items-start border border-indigo-100">
            <AlertTriangle className="w-5 h-5 text-indigo-600 flex-shrink-0 mt-0.5" />
            <div className="text-indigo-800 text-sm text-left">
              <p className="font-medium">One-time only attempt</p>
              <p>You have only one chance to make an impression, so give it your best shot and nail it like a pro!</p>
            </div>
          </div>

          {/* Requirements Grid */}
          {/* Requirements Grid */}
          <div className="w-full space-y-3">
            <p className="text-sm font-medium text-gray-700 text-left">Requirements:</p>
            <div className="grid grid-cols-2 gap-2.5">
              {[
                { Icon: Globe, text: "Stable Internet", key: "internet" },
                { Icon: FileText, text: "Resume Ready", key: "resume" },
                { Icon: Briefcase, text: "Quiet Space", key: "space" },
                { Icon: Shield, text: "No Interruptions", key: "privacy" },
              ].map((item) => (
                <div
                  key={item.key}
                  className="flex items-center gap-3 p-3 bg-white rounded-lg border border-gray-200 hover:border-indigo-200 transition-colors"
                >
                  <div className="p-1.5 bg-indigo-50 rounded-md">
                    <item.Icon className="w-5 h-5 text-indigo-600" />
                  </div>
                  <span className="text-sm font-medium text-gray-700">{item.text}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex gap-3 w-full mt-4">
            <button
              onClick={onClose}
              className="flex-1 px-4 py-2.5 text-gray-700 font-medium rounded-full hover:bg-gray-50 transition-colors border border-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={onStart}
              className="flex-1 px-4 py-2.5 bg-indigo-600 text-white font-medium rounded-full hover:bg-indigo-700 transition-colors shadow-sm hover:shadow-md"
            >
              Begin Interview
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewGuideModal;