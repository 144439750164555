import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    X,
    MessageCircle,
    CheckCircle,
    Upload,
    Video,
    Bot,
    UserSquare,
    Users,
    Share2,
    Rocket,
} from "lucide-react";
import { Transition } from "@headlessui/react";
import confetti from "canvas-confetti";
import VideoUploadModal from "../VideoUploadModal/VideoUploadModal";
import { StartInterviewModal } from "../StartProfileInterviewModal/StartProfileInterviewModal";
import CVUploadModal from "../CVUploadModal/CVUploadModal";
import mp3Sound from "../../assests/Sounds/mixkit-long-pop-2358.mp3"
import { HiOutlineDocumentText, HiOutlineGlobeAlt } from "react-icons/hi2";


export default function OnboardingWidget({ isOnboardingOpen, setIsOnboardingOpen }) {
    const dispatch = useDispatch();
    const { data: profileData, status: profileStatus } = useSelector(
        (state) => state.profile
    );
    const [isOpen, setIsOpen] = useState(false);
    const [showReminder, setShowReminder] = useState(false);
    const popupRef = useRef(null);
    const buttonRef = useRef(null);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [isInterviewModalVisible, setIsInterviewModalVisible] = useState(false);
    const [isCVModalOpen, setIsCVModalOpen] = useState(false);


    const handleVideoModalOpen = () => {
        setIsVideoModalOpen(true);
        setIsOpen(false)

    };

    const handleInterviewModalVisible = () => {
        setIsInterviewModalVisible(true)
        setIsOpen(false)

    };



    const handleisCVModalOpen = () => {
        setIsCVModalOpen(true)
        setIsOpen(false)

    };


    // Get onboarding status from Redux store
    console.log("profileData", profileData);
    const onboardingStatus = profileData?.onboardingStatus || {};
    const userEmail = profileData?.PK || {};

    const extractStatus = (field) =>
        typeof field === "boolean" ? field : field?.status;

    const completedSteps = [
        extractStatus(onboardingStatus.resumeUploaded),
        extractStatus(onboardingStatus.videoUploaded),
        extractStatus(onboardingStatus.aiInterviewTaken),
        extractStatus(onboardingStatus.profilePublished),
    ].filter(Boolean).length;

    const pendingTasks = 4 - completedSteps;

    useEffect(() => {
        if (showReminder && pendingTasks > 0) {
            console.log("playing audio")
            // Play notification sound (ensure /notification.mp3 exists in your public folder)
            const audio = new Audio(mp3Sound);
            audio.play().catch((err) => console.log("playing audio error:", err));


        }
    }, [showReminder]);



    // Fire confetti when all onboarding steps are complete.
    useEffect(() => {
        if (completedSteps === 4) {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 },
            });
        }
    }, [completedSteps]);

    // Open the popup if the parent state indicates so.
    useEffect(() => {
        if (isOnboardingOpen) {
            setIsOpen(true);
        }
    }, [isOnboardingOpen]);

    const handleClose = () => {
        setIsOpen(false);
        setIsOnboardingOpen?.(false);
        // If there are pending tasks, ensure the reminder is shown.
    };

    // Close the popup when clicking outside.
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                handleClose();
            }
        }
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isOpen]);

    // If the parent re-opens the widget, hide the reminder.
    useEffect(() => {
        if (pendingTasks > 0) {
            setShowReminder(true);
        }
    }, []);

    // If the parent re-opens the widget, hide the reminder.
    useEffect(() => {
        if (isOnboardingOpen) {
            setIsOpen(true);
            setShowReminder(false);
        }
    }, [isOnboardingOpen]);


    useEffect(() => {
        if (showReminder) {
            const timer = setTimeout(() => {
                setShowReminder(false);
            }, 9000);
            return () => clearTimeout(timer);
        }
    }, [showReminder]);

    return (
        <>
            {/* Floating Button */}
            <button
                ref={buttonRef}
                onClick={() => {
                    setIsOpen(!isOpen);
                    setShowReminder(false);
                }}

                className="fixed bottom-6 right-6 z-50 bg-gradient-to-br from-indigo-600 to-indigo-500 text-white p-4 rounded-2xl shadow-2xl hover:shadow-3xl transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-4 focus:ring-indigo-400/30"
                aria-haspopup="true"
                aria-expanded={isOpen}
                aria-label="Open onboarding widget"
            >
                <Rocket className="h-6 w-6" />
                <span className="absolute -top-2 -right-2 w-6 h-6 rounded-full bg-rose-500 text-xs font-bold flex items-center justify-center animate-pulse border-2 border-white">
                    {pendingTasks}
                </span>
            </button>

            {/* Reminder Notification */}
            <Transition
                show={showReminder}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed bottom-20 right-6 z-50 bg-white p-4 rounded-xl shadow-lg flex items-center vgap-2 mb-2 border border-indigo-100 transition-all duration-500">
                    <MessageCircle className="h-5 w-5 text-indigo-600 flex-shrink-0 mr-1" />
                    <span className="text-sm font-medium text-gray-800">
                        Hey! You have {pendingTasks} unfinished task
                        {pendingTasks !== 1 ? "s" : ""}.{" "}

                    </span>
                    <button
                        onClick={() => setShowReminder(false)}
                        className="text-gray-400 hover:text-gray-600 ml-2"
                    >
                        <X className="h-4 w-4" />
                    </button>
                </div>
            </Transition>

            {/* Onboarding Popup */}
            <Transition
                show={isOpen}
                enter="transition ease-out duration-300 transform"
                enterFrom="opacity-0 translate-y-4 scale-95"
                enterTo="opacity-100 translate-y-0 scale-100"
                leave="transition ease-in duration-200 transform"
                leaveFrom="opacity-100 translate-y-0 scale-100"
                leaveTo="opacity-0 translate-y-4 scale-95"
            >
                {(ref) => (
                    <div
                        ref={popupRef}
                        className="fixed bottom-[6rem] right-6 z-[222222] w-96 max-w-full bg-white dark:bg-gray-900 backdrop-blur-2xl shadow-2xl rounded-3xl border border-gray-300/70 dark:border-gray-800 overflow-hidden"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="onboarding-title"
                    >
                        {/* Header Section */}
                        <div className="bg-gradient-to-br from-indigo-50 to-white dark:from-indigo-900/40 dark:to-gray-900 px-6 pt-6 rounded-t-3xl pb-1">
                            <div className="flex justify-between items-start mb-4">
                                <div>
                                    <h2
                                        id="onboarding-title"
                                        className="bg-gradient-to-r from-indigo-500 to-indigo-600 bg-clip-text text-transparent text-xl font-bold"
                                    >
                                        Complete Your Profile
                                    </h2>
                                    <p className="text-sm font-normal text-gray-600 dark:text-gray-400 mt-1">
                                        Profile publication status • {Math.round((completedSteps / 4) * 100)}% completed
                                    </p>
                                </div>
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="p-2 rounded-xl hover:bg-indigo-100/50 dark:hover:bg-indigo-900/20 transition-all focus:outline-none focus:ring-2 focus:ring-indigo-400/50"
                                    aria-label="Close onboarding widget"
                                >
                                    <X className="h-5 w-5 text-indigo-600/80 dark:text-indigo-300" />
                                </button>
                            </div>

                            <div className="w-full bg-gray-200/70 dark:bg-gray-800 rounded-full h-2 mb-6">
                                <div
                                    className="bg-gradient-to-r from-indigo-500 to-indigo-600 h-full rounded-full transition-all duration-500"
                                    style={{ width: `${(completedSteps / 4) * 100}%` }}
                                />
                            </div>
                        </div>

                        {/* Body Section */}
                        <div className="px-6 pt-4 pb-6 bg-gradient-to-b from-white/50 to-white dark:from-gray-900/50 dark:to-gray-900">
                            {profileStatus === "loading" && (
                                <div className="text-center py-4 text-gray-500">
                                    Loading onboarding status...
                                </div>
                            )}

                            {profileStatus === "failed" && (
                                <div className="text-center py-4 text-red-500">
                                    Error loading onboarding data
                                </div>
                            )}

                            {profileStatus === "succeeded" && (
                                <div className="space-y-3">
                                    <OnboardingStep
                                        icon={<HiOutlineDocumentText className="w-5 h-5" />}
                                        title="Upload Your Resume"
                                        status={
                                            onboardingStatus.resumeUploaded?.status ? "done" : "pending"
                                        }
                                        subtitle="PDF, DOCX (max 5MB)"
                                        onStart={handleisCVModalOpen}
                                    />
                                    <OnboardingStep
                                        icon={<Video className="w-5 h-5" />}
                                        title="30s Self-Promo Video"
                                        status={
                                            onboardingStatus.videoUploaded?.status ? "done" : "pending"
                                        }
                                        subtitle="MP4, MOV (max 100MB)"
                                        onStart={handleVideoModalOpen}
                                    />
                                    <OnboardingStep
                                        icon={<Bot className="w-5 h-5" />}
                                        title="AI Mock Interview"
                                        status={onboardingStatus.aiInterviewTaken ? "done" : "pending"}
                                        subtitle="15 mins duration"
                                        onStart={handleInterviewModalVisible}
                                    />
                                    <OnboardingStep
                                        icon={<HiOutlineGlobeAlt className="w-5 h-5" />}
                                        title="Publish Profile"
                                        status={onboardingStatus.profilePublished ? "done" : "pending"}
                                        subtitle="Go public"
                                    />
                                </div>
                            )}
                        </div>

                        {/* Footer Section */}
                        <div className="bg-gray-50/70 dark:bg-gray-800/30 border-t border-gray-100/80 dark:border-gray-700 px-6 py-4 rounded-b-3xl">
                            <div className="flex space-x-3">
                                <button className="flex-1 flex items-center justify-center gap-2 px-4 py-2.5 dark:bg-indigo-900/20 text-indigo-600 dark:text-indigo-400 text-sm font-medium rounded-xl hover:bg-indigo-100 dark:hover:bg-indigo-800 transition-all focus:outline-none focus:ring-2 focus:ring-indigo-400">
                                    <Users className="w-5 h-5" />
                                    Community
                                </button>
                                <a
                                    href={`${window.location.origin}/referrals`}

                                    className="flex-1 flex items-center justify-center gap-2 px-4 py-2.5 dark:bg-indigo-900/20 text-indigo-600 dark:text-indigo-400 text-sm font-medium rounded-xl hover:bg-indigo-100 dark:hover:bg-indigo-800 transition-all focus:outline-none focus:ring-2 focus:ring-indigo-400"
                                >
                                    <Share2 className="w-5 h-5" />
                                    Refer
                                </a>

                            </div>

                            <p className="text-center text-sm text-gray-500 dark:text-gray-400 px-6 py-4">
                                Need help?{" "}
                                <a
                                    href="mailto:connect@asendia.ai"
                                    className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-700 dark:hover:text-indigo-300 font-medium transition-colors"
                                >
                                    Contact support
                                </a>
                            </p>

                        </div>
                    </div>
                )}
            </Transition>
            <VideoUploadModal
                isOpen={isVideoModalOpen}
                onClose={() => setIsVideoModalOpen(false)}
            />

            {isInterviewModalVisible && (
                <StartInterviewModal
                    onClose={() => setIsInterviewModalVisible(false)}
                    email={userEmail}
                />
            )}
            <CVUploadModal
                isOpen={isCVModalOpen}
                onClose={() => setIsCVModalOpen(false)}

            />

        </>
    );
}


function OnboardingStep({ icon, title, status, subtitle, onStart }) {
    return (
        <div
            className={`flex items-center p-4 rounded-2xl transition-all border ${status === "done"
                ? "bg-indigo-100/70 dark:bg-indigo-900/20 border-indigo-200/60 dark:border-indigo-800/50 shadow-md shadow-gray-300/50"
                : "bg-white/70 dark:bg-gray-800/30 hover:bg-gray-50/50 dark:hover:bg-gray-700/50 border-gray-200/80 dark:border-gray-600/30 shadow-md shadow-gray-300/50 hover:shadow-md"
                }`}
        >
            <div
                className={`p-2.5 rounded-xl flex-shrink-0 ${status === "done"
                    ? "bg-gradient-to-br from-indigo-100 to-indigo-200 dark:from-indigo-800/30 dark:to-indigo-900/40 text-indigo-600 dark:text-indigo-400"
                    : "bg-gray-100/80 dark:bg-gray-700/50 text-gray-600 dark:text-gray-300"
                    }`}
            >
                {icon}
            </div>

            <div className="ml-4 flex-1">
                <h3
                    className={`text-sm font-semibold ${status === "done"
                        ? "text-indigo-700 dark:text-indigo-300"
                        : "text-gray-800 dark:text-gray-100"
                        }`}
                >
                    {title}
                </h3>
                <p className="mt-1 text-xs text-gray-500/90 dark:text-gray-400/80">
                    {subtitle}
                </p>
            </div>

            <div className="ml-4 flex items-center">
                {status === "done" ? (
                    <span className="flex items-center gap-1 px-3 py-1.5 text-xs font-semibold text-white bg-gradient-to-br from-indigo-500 to-indigo-500 rounded-xl shadow-inner">
                        <CheckCircle className="w-4 h-4" />
                        Done
                    </span>
                ) : (
                    <button
                        onClick={onStart}
                        className="px-4 py-1.5 text-xs font-semibold text-gray-700 bg-gradient-to-br from-gray-200 to-gray-300 hover:from-gray-300 hover:to-gray-400 dark:from-indigo-500 dark:to-indigo-500 dark:hover:from-indigo-600 dark:hover:to-indigo-600 rounded-xl transition-all shadow-sm hover:shadow-md"
                    >
                        Start
                    </button>
                )}
            </div>
        </div>
    );
}