// src/Redux/Slices/userSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  email: '',
  fullName: '',
  profilePictureUrl: '', 
  onboardingStep: 1, // Default to step 1
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      return { ...state, ...action.payload };
    },
    updateProfilePicture(state, action) {
      state.profilePictureUrl = action.payload;
    },
    setOnboardingStep(state, action) {
      state.onboardingStep = action.payload;
    },
    clearUser() {
      return initialState;
    },
    resetState() {
      return initialState;
    },
  },
});

export const {
  setUser,
  updateProfilePicture,
  setOnboardingStep,
  clearUser,
  resetState,
} = userSlice.actions;

export default userSlice.reducer;
