/// src/screens/OnboardingStep3.js
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CountrySelect, StateSelect, CitySelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { updateOnboardingStep, updateTalentHubProfile } from '../../Services/ApiEnpoints';
import { setOnboardingStep } from '../../Redux/Slices/userSlice';
import { BsArrowLeft, BsExclamationCircle } from 'react-icons/bs';
import { motion } from "framer-motion";
import NavBar from './components/NavBar';
import Stepper from './components/Stepper';

const OnboardingStep2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.user) || {};
  const [loading, setLoading] = useState(false);

  // Form states
  const [country, setCountry] = useState({ id: '', name: '' });
  const [state, setState] = useState({ id: '', name: '' });
  const [city, setCity] = useState({ id: '', name: '' });
  const [availability, setAvailability] = useState("");
  const [salary, setSalary] = useState("");
  const [startOption, setStartOption] = useState("");
  const [weeksNotice, setWeeksNotice] = useState("");
  const [error, setError] = useState("");

  // Local state for current question (0-indexed)
  const [formStep, setFormStep] = useState(0);
  const totalSteps = 4;

  // Validate each step before moving on
  const validateCurrentStep = () => {
    if (formStep === 0) {
      if (!country.name || !state.name || !city.name) {
        setError("Please select your country, state, and city");
        return false;
      }
    }
    if (formStep === 1 && !availability) {
      setError("Please select your work availability.");
      return false;
    }
    if (formStep === 2 && (!salary || Number(salary) <= 0)) {
      setError("Please enter a valid salary expectation.");
      return false;
    }
    if (formStep === 3) {
      if (!startOption) {
        setError("Please select when you can start working.");
        return false;
      }
      if (startOption === "notice" && (!weeksNotice || Number(weeksNotice) <= 0)) {
        setError("Please enter a valid number of weeks notice.");
        return false;
      }
    }
    setError("");
    return true;
  };

  // Move to next step if current step is valid
  const handleNext = () => {
    if (!validateCurrentStep()) return;
    if (formStep < totalSteps - 1) {
      setFormStep(formStep + 1);
    }
  };

  // Move to previous step and clear errors if any
  const handleBack = () => {
    if (formStep > 0) {
      setFormStep(formStep - 1);
      setError("");
    }
  };

  // When profile is complete, send all collected data to your API
  const handleCompleteProfile = async () => {
    if (!validateCurrentStep()) return;

    // Combine all form data into one object
    const formData = {
      location: `${city.name}, ${state.name}, ${country.name}`,
      availability,
      salary: Number(salary),
      startOption,
      weeksNotice: startOption === "notice" ? Number(weeksNotice) : null,
    };

    try {
      setLoading(true); // Start loader

      await updateOnboardingStep("3", email);
      dispatch(setOnboardingStep("3"));

      await updateTalentHubProfile(formData); // Call TalentHub API
      navigate("/signup/onboarding-step3");
      console.log("formData", formData);
    } catch (err) {
      console.error("Failed to update profile or step:", err);
      setError("Failed to complete profile. Please try again later.");
    } finally {
      setLoading(false); // Stop loader
    }
  };

  // Use useCallback to avoid unnecessary re-creation of these handlers
  const handleCountryChange = useCallback((e) => {
    setCountry({ id: e.id, name: e.name });
    setState({ id: '', name: '' }); // Reset state
    setCity({ id: '', name: '' });  // Reset city
  }, []);

  const handleStateChange = useCallback((e) => {
    setState({ id: e.id, name: e.name });
    setCity({ id: '', name: '' });
  }, []);

  const handleCityChange = useCallback((e) => {
    setCity({ id: e.id, name: e.name });
  }, []);

  // Render the appropriate form step
  const renderFormStep = () => {
    switch (formStep) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            className="space-y-6"
          >
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                Country
              </label>
              <CountrySelect
                placeHolder="Select Country"
                defaultValue={country}
                onChange={handleCountryChange}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                State
              </label>
              <StateSelect
                key={country.id} // Force re-render when country changes
                placeHolder="Select State"
                countryid={country.id}
                defaultValue={state}
                onChange={handleStateChange}
                disabled={!country.id}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                City
              </label>
              <CitySelect
                key={state.id} // Force re-render when state changes
                placeHolder="Select City"
                countryid={country.id}
                stateid={state.id}
                defaultValue={city}
                onChange={handleCityChange}
                disabled={!state.id}
              />
            </div>
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <label className="block text-sm font-medium text-gray-700 mb-3">
              What’s your work availability?
            </label>
            <div className="space-y-3">
              {['full-time', 'part-time', 'flexible'].map((option) => (
                <label
                  key={option}
                  className={`flex items-center space-x-3 p-4 border-2 rounded-xl cursor-pointer transition-all duration-200 ${
                    availability === option
                      ? 'border-indigo-500 bg-indigo-50/50'
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  <input
                    type="radio"
                    name="availability"
                    value={option}
                    checked={availability === option}
                    onChange={(e) => setAvailability(e.target.value)}
                    className="h-4 w-4 text-indigo-600 border-2 border-gray-300 focus:ring-indigo-500"
                  />
                  <div className="flex-1">
                    <p className="font-medium text-gray-900">
                      {option === 'full-time' && 'Full time'}
                      {option === 'part-time' && 'Part time'}
                      {option === 'flexible' && 'I’m flexible'}
                    </p>
                    <p className="text-gray-500 text-sm mt-1">
                      {option === 'full-time' && 'At least 8 hours per day'}
                      {option === 'part-time' && 'At least 4 hours per day'}
                      {option === 'flexible' && 'Available for both full or part-time roles'}
                    </p>
                  </div>
                </label>
              ))}
            </div>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <label className="block text-sm font-medium text-gray-700 mb-3">
              Monthly Salary Expectation (USD)
            </label>
            <div className="relative">
              <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">$</span>
              <input
                type="number"
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
                placeholder="e.g. 2500"
                className="w-full border-2 border-gray-200 rounded-xl pl-9 pr-4 py-3 text-base focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 transition-all duration-200"
              />
            </div>
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <label className="block text-sm font-medium text-gray-700 mb-3">
              When can you start working?
            </label>
            <div className="grid grid-cols-2 gap-4">
              {['immediate', 'notice'].map((option) => (
                <div
                  key={option}
                  onClick={() => setStartOption(option)}
                  className={`p-4 border-2 rounded-xl cursor-pointer transition-all duration-200 ${
                    startOption === option
                      ? 'border-indigo-500 bg-indigo-50/50'
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  <p className="font-medium text-gray-900 text-center">
                    {option === 'immediate'
                      ? 'I can start within 24 hours'
                      : 'Weeks of notice'}
                  </p>
                </div>
              ))}
            </div>
            {startOption === "notice" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className="mt-6"
              >
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  How many weeks of notice?
                </label>
                <input
                  type="number"
                  value={weeksNotice}
                  onChange={(e) => setWeeksNotice(e.target.value)}
                  placeholder="e.g. 2"
                  className="w-full border-2 border-gray-200 rounded-xl px-4 py-3 text-base focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 transition-all duration-200"
                />
              </motion.div>
            )}
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-white overflow-auto">
      <NavBar
        links={[
          { label: "Questions?", onClick: () => {} },
          { label: "Applied already? Log in", oonClick: () => navigate("/signin") }
        ]}
      />
      <div className="-mt-5">
        <Stepper currentStep={1} />
      </div>

      <div className="flex flex-col items-center justify-center px-4 sm:px-6 py-20 -mt-14">
        {/* Reduced max width from 2xl to xl for a slightly smaller card */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="w-full max-w-xl bg-white rounded-3xl shadow-2xl shadow-gray-300/30 border border-gray-300/60 hover:shadow-2xl hover:shadow-gray-300/40 p-6 sm:p-10 relative"
        >
          <button
            className="text-gray-600 hover:text-gray-800 mb-6 flex items-center gap-2 transition-colors"
            onClick={() => navigate("/signup/onboarding-step1")}
          >
            <BsArrowLeft className="inline-block" />
            <span className="text-sm font-medium">Back</span>
          </button>

          <div className="mb-8">
            <h1 className="text-2xl font-bold text-gray-900 mb-2">
              Complete Your Profile
            </h1>
            <p className="text-gray-600 text-sm">
              Answer a few quick questions to help us get to know you better.
            </p>
          </div>

          <div className="space-y-8">
            {renderFormStep()}
          </div>

          {error && (
            <div className="mt-6 flex items-center gap-2 text-red-600">
              <BsExclamationCircle className="flex-shrink-0" />
              <p className="text-sm">{error}</p>
            </div>
          )}

          <div className="mt-8 flex justify-between items-center gap-4">
            {formStep > 0 && (
              <button
                onClick={handleBack}
                className="py-2.5 px-5 rounded-xl font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 transition-colors text-sm"
              >
                Back
              </button>
            )}
            <div className="ml-auto flex gap-3">
              {formStep < totalSteps - 1 ? (
                <button
                  onClick={handleNext}
                  className="py-2.5 px-6 rounded-xl font-semibold text-white bg-gradient-to-r from-indigo-600 to-indigo-500 hover:from-indigo-700 hover:to-indigo-600 transition-all text-sm shadow-sm"
                >
                  Continue
                </button>
              ) : (
                <button
                  onClick={handleCompleteProfile}
                  disabled={loading}
                  className={`py-2.5 px-6 rounded-xl font-semibold text-white 
                    bg-gradient-to-r from-indigo-600 to-indigo-500 
                    hover:from-indigo-700 hover:to-indigo-600 
                    transition-all text-sm shadow-sm 
                    ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
                >
                  {loading ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin h-4 w-4 mr-2 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v4l3-3m-3 3L8 4"
                        ></path>
                      </svg>
                      Processing...
                    </span>
                  ) : (
                    "Complete Profile"
                  )}
                </button>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default OnboardingStep2;
