// App.js
import React, { useState, useEffect, memo, useCallback, lazy, Suspense, useRef } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// Imported Tour component
import { updateProfilePicture, clearUser } from './Redux/Slices/userSlice';
import { setBuddyList, setDarkMode, resetState as resetBuddyState } from './Redux/Slices/BuddySlice';
import { checkToken, getAllBuddies, getProfilePicture } from './Services/ApiEnpoints';
import AuthenticatedRoute from './Services/AuthenticatedRoute';
import { Toast } from './Components/Toast/Toast';
import FeedbackPopUp from './Components/Sidebar/Component/FeedbackPopUp';
import BuddyLoader from './Components/Loader/BuddyLoader';
import BuddyErrorPage from './Pages/Error/BuddyError';
import { resetState as resetPdfState } from './Redux/Slices/userSlice';
import { resetState as resetUserState } from './Redux/Slices/userSlice';
import TemplatesMockInterview from './Pages/BuddyCalls/TemplatesMockInterview';
import PreInterviewScreen from './Pages/LandingPageTemplates/GoogleInterview/PreInterviewScreen';
import OnboardingStep1 from './Pages/Onboarding/OnboardingStep1';
import OnboardingStep3 from './Pages/Onboarding/OnboardingStep3';
import OnboardingStep2 from './Pages/Onboarding/OnboardingStep2';
import OnboardingPopup from './Components/OnboardingPopup/OnboardingPopup';
import TalenthubVideoInterview from './Main/Components/MyProfile/Steps/components/Talenthub_VideoInterview';
import { clearProfile, fetchProfileData } from "./Redux/Slices/profileSlice";
import PublicProfile from './Pages/PublicProfile/PublicProfile';
import TourComponent from './Components/TourComponenet/Tour';

const Navbar = lazy(() => import('./Components/Navbar/Navbar'));
const Sidebar = lazy(() => import('./Components/Sidebar/Sidebar'));
const Content = lazy(() => import('./Main/Content'));
const Signin = lazy(() => import('./Pages/Signin/Signin'));
const Signup = lazy(() => import('./Pages/Signup/Signup'));
const AudioInterview = lazy(() => import('./Pages/BuddyCalls/AudioInterview'));
const VideoInterview = lazy(() => import('./Pages/BuddyCalls/VideoInterview'));

const isPublicProfile = window.location.pathname.startsWith("/profile/");
const authRoutes = ['/signin', '/signup', '/error'];
const interviewRoutes = ['/buddy/video-interview', '/buddy/audio-interview', '/ai-video-interview'];
const onBoardingRoutes = ['/signup/onboarding-step1', '/signup/onboarding-step2', '/signup/onboarding-step3'];

const App = () => {
  // UI states
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('AI Interview Buddy');
  const [feedback, setFeedback] = useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [activeOption, setActiveOption] = useState('AI Interview Buddy');
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const [activeSubmenuOption, setActiveSubmenuOption] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Redux & routing hooks
  const { toastDetails: { uniqueId, toaststate, message, icon, actionButton } } = useSelector((state) => state.toastSliceInfo);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Retrieve stored user data and token
  const userData = JSON.parse(localStorage.getItem('userData'));
  const jwtToken = localStorage.getItem('accessToken');

  // Grab buddy list from Redux to avoid re-fetching if already loaded
  const buddyListFromStore = useSelector((state) => state.buddy.buddyList);

  // --- CALLBACKS ---

  // Redirect to sign in and clear storage (if necessary)
  const redirectToSignIn = useCallback((msg) => {
    console.log(msg);
    const templateInfo = localStorage.getItem("templateInfo");
    const temp_id = localStorage.getItem("temp_id");
    localStorage.clear();
    sessionStorage.clear();
    if (templateInfo) localStorage.setItem("templateInfo", templateInfo);
    if (temp_id) localStorage.setItem("temp_id", temp_id);
    console.log("Cleared all storage except templateInfo and temp_id.");
    if (!authRoutes.includes(window.location.pathname) && !isPublicProfile) {
      console.log("Redirecting to sign-in...");
      navigate("/signin");
    }
    setLoading(false);
  }, [navigate]);

  const isPersistLoaded = useSelector((state) => state._persist?.rehydrated);
  const profileDataFromStore = useSelector((state) => state.profile.data);
  const dataFetched = useRef(false);





  const loadData = useCallback(async () => {
    if (!isPersistLoaded) return; // 🔹 Wait for Redux to finish rehydrating

    const token = localStorage.getItem('accessToken');
    if (!token) {
      redirectToSignIn("No token found. Redirecting to login...");
      return;
    }

    try {
      const isValidToken = await checkToken(token);
      if (!isValidToken) {
        redirectToSignIn("Invalid token. Redirecting to login...");
        return;
      }

      if (userData) {
        console.log("Token is valid, proceeding to load data...");

        // 🔹 Fetch profile picture if not already loaded
        if (!profileDataFromStore) {
          try {
            const profileResponse = await getProfilePicture(userData?.id);
            if (profileResponse?.url) {
              dispatch(updateProfilePicture(profileResponse.url));
            }
          } catch (error) {
            console.warn("No profile picture found or error fetching profile picture, proceeding without it.");
          }
        }

        // 🔹 Force-fetch buddies **AFTER Redux is ready**
        console.log("Checking buddy list in Redux store:", buddyListFromStore);
        if (!buddyListFromStore || buddyListFromStore.length === 0) {
          console.log("Fetching buddy list from API...");
          try {
            const buddyData = await getAllBuddies(userData?.id, userData?.email);
            dispatch(setBuddyList(buddyData)); // ✅ Dispatch immediately after fetch
          } catch (error) {
            console.error('Error fetching buddies:', error);
          }
        } else {
          console.log("Buddy list already exists, skipping API call.");
        }
      }
    } catch (error) {
      console.error("Token check failed. Redirecting to login...", error);
      redirectToSignIn("Token is missing or expired. Redirecting to login...");
    } finally {
      setLoading(false);
    }
  }, [dispatch, userData, redirectToSignIn, buddyListFromStore, isPersistLoaded, profileDataFromStore]);

  // ✅ Run only after Redux rehydration is completed
  useEffect(() => {
    if (!dataFetched.current && isPersistLoaded) {
      console.log("Redux is rehydrated. Running loadData...");
      loadData();
      dataFetched.current = true;
    }
  }, [loadData, isPersistLoaded]);

  // Fetch profile data (after Redux Persist rehydrates) if not already available
  const { data } = useSelector((state) => state.profile);
  console.log("Redux profile data:", data, isPersistLoaded);
  useEffect(() => {
    if (isPersistLoaded && !data) {
      console.log("Fetching profile data...");
      dispatch(fetchProfileData());
    }
  }, [isPersistLoaded, data, dispatch]);

  const hasFetchedOnMount = useRef(false);
  const hasFetchedOnAuthChange = useRef(false);

  useEffect(() => {
    // Fetch once on mount
    if (!hasFetchedOnMount.current) {
      console.log("Fetching profile data on page load...");
      dispatch(fetchProfileData());
      hasFetchedOnMount.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    // Fetch once when userData or jwtToken arrives
    if (!hasFetchedOnAuthChange.current && (jwtToken || userData)) {
      console.log("Fetching profile data since JWT or user data became available...");
      dispatch(fetchProfileData());
      hasFetchedOnAuthChange.current = true;
    }
  }, [jwtToken, userData, dispatch]);

  // Handle logout if a logout message exists in storage
  useEffect(() => {
    const logoutMessage = localStorage.getItem('logoutMessage');
    if (logoutMessage === 'User is logged out') {
      localStorage.removeItem('logoutMessage');
      localStorage.clear();
      sessionStorage.clear();
      dispatch(resetBuddyState());
      dispatch(resetPdfState());
      dispatch(resetUserState());
      dispatch(clearUser());
      dispatch(clearProfile());
      navigate("/signin");
    }
  }, [dispatch, navigate]);

  // Determine routes and dynamic values
  const isAuthRoute = authRoutes.includes(location.pathname);
  const storedTemplateInfo = localStorage.getItem('templateInfo');
  const temp_id = localStorage.getItem('temp_id');
  const dynamicRoute = temp_id ? `/buddy/${temp_id}` : null;
  const videoInterviewRoute = dynamicRoute ? `${dynamicRoute}/video-interview` : null;
  console.log("videoInterviewRoute", videoInterviewRoute);

  const isInterviewRoute = interviewRoutes.includes(location.pathname) ||
    (dynamicRoute && location.pathname.startsWith(dynamicRoute));
  const isOnBoardingRoute = onBoardingRoutes.includes(location.pathname);

  // Process URL query parameters only if present (runs once)
  useEffect(() => {
    if (!window.location.search) return;
    const params = new URLSearchParams(window.location.search);
    if ([...params.keys()].length === 0) return;
    const title = params.get("title");
    const source = params.get("from");
    const temp_id_param = params.get("id");
    console.log("Template Title:", title);
    console.log("Source:", source);
    if (title && source && temp_id_param) {
      const templateData = { title, source, temp_id: temp_id_param };
      localStorage.setItem("templateInfo", JSON.stringify(templateData));
      localStorage.setItem("temp_id", temp_id_param);
      console.log("Template info stored in localStorage:", templateData);
      console.log("Temp ID stored separately:", temp_id_param);
      const dynamicRouteParam = temp_id_param ? `/buddy/${temp_id_param}` : null;
      if (dynamicRouteParam) {
        navigate(dynamicRouteParam);
      } else {
        navigate('/buddy/ai-interview-buddy');
      }
    } else {
      console.log("One or more required parameters are missing. Data not stored.");
    }
  }, [navigate]);

  // Apply theme settings on mount
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const isDarkMode = storedTheme === 'dark';
    dispatch(setDarkMode(isDarkMode));
    document.documentElement.classList.toggle('dark', isDarkMode);
  }, [dispatch]);

  // UI handlers
  const toggleShowMore = () => setIsShowingMore((prev) => !prev);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const closeTour = () => {
    setIsTourOpen(false);
    setCurrentStep(null);
  };
  const openTour = () => {
    console.log("Tour opened, please check");
    setTimeout(() => {
      setIsTourOpen(true);
    }, 300); // Delays opening the tour
  };

  return (
    <div className="relative w-screen max-h-screen flex bg-[#fdfdfd] dark:bg-[rgb(15,23,42)] overflow-hidden">
      {loading ? (
        <BuddyLoader openLoader={loading} handleClose={() => { }} />
      ) : (
        <>
          <Suspense fallback={
            <div style={{
              width: '100vw', height: '100vh', display: 'flex',
              justifyContent: 'center', alignItems: 'center', background: 'white'
            }}></div>
          }>
            {isAuthRoute && (
              <div className="h-screen w-full flex flex-col mx-auto">
                <Routes>
                  <Route path="/signin" element={<Signin openTour={openTour} />} />
                  <Route path="/signup" element={<Signup openTour={openTour} />} />
                  <Route path="/error" element={<BuddyErrorPage />} />
                  <Route path="*" element={<Navigate to="/signin" replace />} />
                </Routes>
              </div>
            )}
            {!isAuthRoute && !jwtToken && (
              <div className="h-screen w-full flex flex-col mx-auto">
                <Routes>
                  <Route path="/signup" element={<Signup openTour={openTour} />} />
                  <Route path="/error" element={<BuddyErrorPage />} />
                  <Route path="/profile/:token" element={<PublicProfile />} />
                  <Route path="*" element={<Navigate to="/signin" replace />} />
                </Routes>
              </div>
            )}
            {!isAuthRoute && jwtToken && (
              <>
                {isOnBoardingRoute ? (
                  <div className="h-screen w-screen flex flex-col mx-auto">
                    <Routes>
                      <Route element={<AuthenticatedRoute />}>
                        <Route path="/signup/onboarding-step1" element={<OnboardingStep1 />} />
                        <Route path="/signup/onboarding-step2" element={<OnboardingStep2 />} />
                        <Route path="/signup/onboarding-step3" element={<OnboardingStep3 />} />
                        <Route path="*" element={<Navigate to="/signup/onboarding-step1" replace />} />
                      </Route>
                    </Routes>
                  </div>
                ) : isInterviewRoute ? (
                  <div className="h-screen w-screen flex flex-col mx-auto">
                    <Routes>
                      <Route element={<AuthenticatedRoute />}>
                        <Route path="/buddy/video-interview" element={<VideoInterview />} />
                        <Route path="/buddy/audio-interview" element={<AudioInterview />} />
                        <Route path="/ai-video-interview" element={<TalenthubVideoInterview />} />
                        {dynamicRoute && (
                          <>
                            <Route path={dynamicRoute} element={<PreInterviewScreen templateInfo={storedTemplateInfo} />} />
                            <Route path={videoInterviewRoute} element={<TemplatesMockInterview templateInfo={storedTemplateInfo} />} />
                          </>
                        )}
                        <Route path="*" element={<Navigate to="/buddy/ai-interview-buddy" replace />} />
                      </Route>
                    </Routes>
                  </div>
                ) : (
                  <>
                    <div className={`${sidebarOpen ? 'w-[20%]' : 'w-[5.1%] min-w-[60px]'} h-full transition-all duration-200`}>
                      <Sidebar
                        currentStep={currentStep}
                        sidebarOpen={sidebarOpen}
                        feedback={feedback}
                        setFeedback={setFeedback}
                        setSidebarOpen={setSidebarOpen}
                        setSelectedOption={setSelectedOption}
                        selectedOption={selectedOption}
                        setActiveOption={setActiveOption}
                        activeOption={activeOption}
                        setIsSubmenuVisible={setIsSubmenuVisible}
                        isSubmenuVisible={isSubmenuVisible}
                        activeSubmenuOption={activeSubmenuOption}
                        setActiveSubmenuOption={setActiveSubmenuOption}
                      />
                    </div>
                    <div className="dark:bg-[#121417] p-[10px] h-screen w-full flex flex-col mx-auto">
                      <div className="border border-gray-200/70  h-full rounded-[19px] shadow-[0px_6px_12px_-2px_rgba(50,50,93,0.15),_0px_3px_7px_-3px_rgba(0,0,0,0.20)]">
                        <div className="h-[12%] max-h-[12%] w-full">
                          <Navbar
                            currentStep={currentStep}
                            sidebarOpen={sidebarOpen}
                            selectedOption={selectedOption}
                            toggleShowMore={toggleShowMore}
                            isShowingMore={isShowingMore}
                          />
                        </div>
                        <div className="rounded-bl-[18px] rounded-br-[18px] dark:border dark:border-[#333333] dark:border-t-0 dark:bg-[#19191c] bg-[#ffffff] flex flex-col w-full h-[88%] overflow-scroll">
                          <Routes>
                            <Route element={<AuthenticatedRoute />}>
                              <Route path="/" element={<Navigate to="/buddy/ai-interview-buddy" replace />} />
                              <Route path="/buddy/ai-interview-buddy" element={<Content openTour={openTour} setActiveSubmenuOption={setActiveSubmenuOption} setIsSubmenuVisible={setIsSubmenuVisible} setActiveOption={setActiveOption} selectedOption="AI Interview Buddy" toggleShowMore={toggleShowMore} isShowingMore={isShowingMore} />} />
                              <Route path="/buddy/coffee-call-buddy" element={<Content openTour={openTour} setActiveSubmenuOption={setActiveSubmenuOption} setIsSubmenuVisible={setIsSubmenuVisible} setActiveOption={setActiveOption} selectedOption="Coffee Call Buddy" />} />
                              <Route path="/expert-interview-templates" element={<Content openTour={openTour} setActiveSubmenuOption={setActiveSubmenuOption} setIsSubmenuVisible={setIsSubmenuVisible} setActiveOption={setActiveOption} selectedOption="Expert Interview Templates" />} />

                              <Route path="/buddy/ai-interview-buddy/:buddyId" element={<Content openTour={openTour} setActiveSubmenuOption={setActiveSubmenuOption} setIsSubmenuVisible={setIsSubmenuVisible} setActiveOption={setActiveOption} selectedOption="AI Interview Buddy" toggleShowMore={toggleShowMore} isShowingMore={isShowingMore} />} />
                              <Route path="/buddy/coffee-call-buddy/:buddyId" element={<Content openTour={openTour} setActiveSubmenuOption={setActiveSubmenuOption} setIsSubmenuVisible={setIsSubmenuVisible} setActiveOption={setActiveOption} selectedOption="Coffee Call Buddy" />} />
                              <Route path="/templates" element={<Content openTour={openTour} selectedOption="Templates" />} />
                              <Route path="/assessment-report" element={<Content openTour={openTour} selectedOption="Assessment Report" />} />
                              <Route path="/referrals" element={<Content openTour={openTour} selectedOption="Referrals" />} />
                              <Route path="/dashboard" element={<Content openTour={openTour} selectedOption="Dashboard" />} />
                              <Route path="/call-history" element={<Content openTour={openTour} selectedOption="Call History" />} />
                              <Route path="/billing" element={<Content openTour={openTour} selectedOption="Billing" />} />
                              <Route path="/account" element={<Content openTour={openTour} selectedOption="Account" />} />
                              <Route path="/my-profile" element={<Content openTour={openTour} selectedOption="My Profile" />} />
                              <Route path="*" element={<Navigate to="/buddy/ai-interview-buddy" replace />} />
                            </Route>
                          </Routes>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Suspense>
          {feedback && (
            <FeedbackPopUp
              setFeedback={setFeedback}
              userEmail={userData?.email}
              userId={userData?.id}
              userFullName={userData?.fullName}
            />
          )}
          <Toast key={uniqueId} toaststate={toaststate} message={message} icon={icon} actionButton={actionButton} />
          <TourComponent

            closeTour={closeTour}
            disableBody={disableBody}
            enableBody={enableBody}
            setCurrentStep={(step) => setCurrentStep(step)}
            isTourOpen={isTourOpen}
          />
        </>
      )}
    </div>
  );
};

export default memo(App);
