import { useState } from "react";
import { X, Upload, FileText, CheckCircle, AlertCircle } from "lucide-react";
import { processCV } from "../../Services/ApiEnpoints";

export default function CVUploadModal({ isOpen, onClose, user_id }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Validate file type & size (max 5MB)
      const allowedTypes = [
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please upload a PDF or DOCX file.");
        setSelectedFile(null);
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        // 5MB limit
        setError("File size exceeds 5MB. Please choose a smaller file.");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
      setError(null); // Clear previous errors
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("Please select a file first.");
      return;
    }

    setUploading(true);
    setError(null);
    setSuccessMessage("");

    try {
      // Await the API call and assign its response
      const response = await processCV(selectedFile);
      

      // Check for errors in resumeUploaded
      if (response?.result?.onboardingStatus?.resumeUploaded?.error) {
        const resumeError = response.result.onboardingStatus.resumeUploaded.error;
        console.error("Resume Upload Error:", resumeError);
        setError(resumeError);
        return
      }

      // Check if the resume upload was successful
      if (!response?.result?.onboardingStatus?.resumeUploaded?.status) {
        console.error("Resume processing failed: Invalid resume uploaded.");
        setError("Resume processing failed. Please upload a valid resume.");
        return
      }

      setSuccessMessage("CV uploaded successfully!");
    } catch (err) {
      setError("Upload failed. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 backdrop-blur-sm">
      <div
        onClick={onClose}
        className="fixed inset-0 bg-black/30 flex items-center justify-center p-5 transition-all duration-300"
      >
        <div
          className="bg-white p-1 rounded-3xl shadow-2xl w-full max-w-md transform transition-all duration-300 overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between items-center px-6 py-5 border-b">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-indigo-50 rounded-lg">
                <FileText className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  Upload Your CV
                </h3>
                <p className="text-sm text-gray-500">
                  Recruiters will see your latest resume
                </p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-50 rounded-lg transition-colors"
              aria-label="Close"
            >
              <X className="h-5 w-5 text-gray-500" />
            </button>
          </div>

          <div className="p-6 space-y-6">
            <div className="space-y-3 text-sm">
              <div className="flex items-center gap-3 text-gray-600">
                <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                <span>Upload a PDF file</span>
              </div>
              <div className="flex items-center gap-3 text-gray-600">
                <AlertCircle className="h-5 w-5 text-indigo-500 flex-shrink-0" />
                <span>Max file size: 5MB</span>
              </div>
            </div>

            <div className="group relative">
              <label
                htmlFor="cv-upload"
                className={`flex flex-col items-center justify-center w-full p-6 border-2 border-dashed rounded-xl cursor-pointer transition-all
                                    ${
                                      selectedFile
                                        ? "border-indigo-100 bg-indigo-50"
                                        : "border-gray-200 hover:border-indigo-300 bg-gray-50/50"
                                    }`}
              >
                <div className="flex flex-col items-center space-y-2">
                  <div className="p-3 bg-white rounded-full shadow-sm border">
                    <Upload
                      className={`h-6 w-6 transition-colors ${
                        selectedFile ? "text-indigo-600" : "text-gray-400"
                      }`}
                    />
                  </div>
                  <div className="text-center">
                    {selectedFile ? (
                      <p className="font-medium text-indigo-600">
                        {selectedFile.name}
                      </p>
                    ) : (
                      <p className="font-medium text-gray-600">
                        Drag & drop or{" "}
                        <span className="text-indigo-600">browse files</span>
                      </p>
                    )}
                    <p className="text-xs text-gray-400 mt-1">Max 5MB</p>
                  </div>
                </div>
              </label>
              <input
                id="cv-upload"
                type="file"
                className="hidden"
                accept=".pdf,.docx"
                onChange={handleFileChange}
              />
            </div>

            {error && <p className="text-red-500 text-sm">{error}</p>}
            {successMessage && (
              <p className="text-green-500 text-sm">{successMessage}</p>
            )}
          </div>

          <div className="px-6 py-4 bg-gray-50 flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-5 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleUpload}
              className={`px-5 py-2 text-sm font-medium text-white rounded-full transition-all
                                ${
                                  selectedFile && !uploading
                                    ? "bg-indigo-600 hover:bg-indigo-700 shadow-md"
                                    : "bg-gray-300 cursor-not-allowed"
                                }`}
              disabled={!selectedFile || uploading}
            >
              {uploading ? "Uploading..." : "Upload CV"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
