import { useState, useEffect, useRef } from 'react';
import {
  FiCheckCircle,
  FiFileText,
  FiClipboard,
  FiClock,
  FiX,
  FiVideo,
  FiMic,
  FiArrowRight,
  FiArrowLeft,
  FiAlertCircle
} from 'react-icons/fi';
import { create_talentHub_interviewer } from '../../Services/ApiEnpoints';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle } from 'lucide-react';

export const StartInterviewModal = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState('guidelines');
  const [mediaStream, setMediaStream] = useState(null);
  const [devices, setDevices] = useState({ camera: false, mic: false });
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const audioAnalyserRef = useRef(null);
  const modalRef = useRef(null);
  const navigate = useNavigate();

  // Starts the interview process once all permissions are confirmed
  const startInterview = async () => {
    if (!devices.camera || !devices.mic) {
      alert('Please enable camera and microphone access to start the interview.');
      return;
    }
    setIsLoading(true);
    try {
      const requestId = `req_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
      const currentRoute = window.location.pathname;
      const response = await create_talentHub_interviewer();
      navigate('/ai-video-interview', {
        state: {
          interviewResponse: response,
          requestId,
          previousRoute: currentRoute,
          additionalState: { userEmail: 'user@example.com' } // Replace with your email variable
        }
      });
    } catch (err) {
      console.error('Failed to start interview:', err);
      alert(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  // The steps for the modal progress
  const steps = [
    { id: 'guidelines', title: 'Interview Overview' },
    { id: 'deviceCheck', title: 'Device Setup' },
    { id: 'ready', title: 'Ready to Start' }
  ];

  // Request access to camera and microphone
  const requestMediaDevices = async () => {
    try {
      setIsLoading(true);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play().catch(console.error);
      }
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);
      microphone.connect(analyser);
      audioAnalyserRef.current = analyser;

      setMediaStream(stream);
      setDevices({
        camera: stream.getVideoTracks().length > 0,
        mic: stream.getAudioTracks().length > 0
      });
    } catch (error) {
      console.error('Media access error:', error);
      alert('Please enable camera and microphone access to continue.');
    } finally {
      setIsLoading(false);
    }
  };

  // Update video element when mediaStream changes
  useEffect(() => {
    if (videoRef.current && mediaStream) {
      videoRef.current.srcObject = mediaStream;
      videoRef.current.play().catch(console.error);
    }
  }, [mediaStream]);

  // Clean up media tracks on component unmount
  useEffect(() => {
    return () => mediaStream?.getTracks().forEach(track => track.stop());
  }, [mediaStream]);

  // Visualize the microphone level in the deviceCheck step
  useEffect(() => {
    if (!audioAnalyserRef.current || currentStep !== 'deviceCheck') return;
    const analyser = audioAnalyserRef.current;
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    const canvas = document.getElementById('micLevel');
    const ctx = canvas?.getContext('2d');

    const draw = () => {
      requestAnimationFrame(draw);
      if (!ctx) return;
      analyser.getByteFrequencyData(dataArray);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const lineWidth = 3;
      const spacing = 4;
      let x = 0;
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = '#4f46e5'; // Indigo-600
      for (let i = 0; i < dataArray.length; i += 4) {
        const amplitude = dataArray[i] / 2.5;
        ctx.beginPath();
        ctx.moveTo(x + spacing, canvas.height / 2);
        ctx.lineTo(x + spacing, canvas.height / 2 - amplitude);
        ctx.stroke();
        x += spacing + lineWidth;
      }
    };
    draw();
  }, [currentStep]);

  const handleNext = async () => {
    if (currentStep === 'guidelines') {
      await requestMediaDevices();
      setCurrentStep('deviceCheck');
    } else if (currentStep === 'deviceCheck') {
      setCurrentStep('ready');
    }
  };

  const handleBack = () => {
    if (currentStep === 'deviceCheck') setCurrentStep('guidelines');
    else if (currentStep === 'ready') setCurrentStep('deviceCheck');
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm p-4">
      <div
        ref={modalRef}
        className="w-full max-w-2xl bg-white rounded-2xl shadow-xl max-h-[90vh] overflow-auto"
      >
        {/* Header */}
        <div className="relative px-6 py-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              {currentStep !== 'guidelines' && (
                <button
                  onClick={handleBack}
                  className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <FiArrowLeft className="w-5 h-5" />
                </button>
              )}
              <div>
                <h2 className="text-xl font-semibold text-gray-800">
                  {steps.find((s) => s.id === currentStep)?.title}
                </h2>
                <div className="flex gap-2 mt-2">
                  {steps.map((step) => (
                    <div
                      key={step.id}
                      className={`h-1 w-8 rounded-full transition-all ${
                        currentStep === step.id ? 'bg-indigo-600' : 'bg-gray-300'
                      }`}
                    />
                  ))}
                </div>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <FiX className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Modal Content */}
        <div className="p-6 space-y-8">
        {currentStep === 'guidelines' && (
                      <div className="space-y-6">
                          <div className="grid md:grid-cols-2 gap-4">
                              <div className="p-5 bg-white border border-gray-200 rounded-xl space-y-3">
                                  <div className="inline-flex items-center gap-3 text-indigo-600">
                                      <FiFileText className="w-6 h-6" />
                                      <h3 className="text-lg font-semibold">CV-Based Questions</h3>
                                  </div>
                                  <p className="text-gray-600 text-sm leading-relaxed">
                                      Prepare to discuss your professional journey, educational background,
                                      and key project contributions in detail.
                                  </p>
                              </div>

                              <div className="p-5 bg-white border border-gray-200 rounded-xl space-y-3">
                                  <div className="inline-flex items-center gap-3 text-indigo-600">
                                      <FiClipboard className="w-6 h-6" />
                                      <h3 className="text-lg font-semibold">Skill Assessment</h3>
                                  </div>
                                  <p className="text-gray-600 text-sm leading-relaxed">
                                      Demonstrate your technical expertise through real-world scenarios
                                      and problem-solving challenges.
                                  </p>
                              </div>
                          </div>
                          <div className="w-full bg-indigo-50 p-3 rounded-lg flex gap-2 items-start border border-indigo-100">
                              <AlertTriangle className="w-5 h-5 text-indigo-600 flex-shrink-0 mt-0.5" />
                              <div className="text-indigo-800 text-sm text-left">
                                  <p className="font-medium">One-time only attempt</p>
                                  <p>You have only one chance to make an impression, so give it your best shot and nail it like a pro!</p>
                              </div>
                          </div>
                          <div className="space-y-4">
                              {[
                                  {
                                      icon: FiCheckCircle,
                                      color: 'text-gray-500',   
                                      title: 'Technical Setup',
                                      content: 'Stable internet connection & power source'
                                  },
                                  {
                                      icon: FiClock,
                                      color: 'text-gray-500',
                                      title: 'Time Management',
                                      content: '15 minute session'
                                  },
                                  {
                                      icon: FiAlertCircle,
                                      color: 'text-gray-500',
                                      title: 'Environment',
                                      content: 'Quiet space with neutral background'
                                  }
                              ].map((item, idx) => (
                                  <div key={idx} className="flex items-start gap-4 p-3 bg-gray-50 rounded-lg">
                                      <div className={`pt-1 ${item.color}`}>
                                          <item.icon className="w-5 h-5" />
                                      </div>
                                      <div>
                                          <h4 className="text-gray-900 font-medium">{item.title}</h4>
                                          <p className="text-gray-600 text-sm mt-1">{item.content}</p>
                                      </div>
                                  </div>
                              ))}
                          </div>



                          <button
                              onClick={handleNext}
                              className="w-full py-3.5 rounded-full bg-indigo-500 hover:bg-indigo-600 text-white font-medium transition-colors flex items-center justify-center gap-2"
                          >
                              Start Device Check
                              <FiArrowRight className="w-4 h-4" />
                          </button>
                      </div>
                    )}

          {currentStep === 'deviceCheck' && (
            <div className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                {/* Setup Instructions */}
                <div className="space-y-4">
                  <div className="p-5 bg-gray-50 rounded-xl border border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800 mb-3">Setup Guide</h3>
                    <ol className="space-y-3 text-sm text-gray-700">
                      <li className="flex items-center gap-3">
                        <span className="flex items-center justify-center w-6 h-6 bg-white text-indigo-600 rounded-full border border-gray-300">
                          1
                        </span>
                        Allow browser permissions for camera &amp; microphone.
                      </li>
                      <li className="flex items-center gap-3">
                        <span className="flex items-center justify-center w-6 h-6 bg-white text-indigo-600 rounded-full border border-gray-300">
                          2
                        </span>
                        Ensure proper lighting and centered framing.
                      </li>
                      <li className="flex items-center gap-3">
                        <span className="flex items-center justify-center w-6 h-6 bg-white text-indigo-600 rounded-full border border-gray-300">
                          3
                        </span>
                        Speak clearly to test your microphone.
                      </li>
                    </ol>
                  </div>
                  <button
                    onClick={requestMediaDevices}
                    className="w-full py-2 rounded-lg border border-indigo-600 text-indigo-600 hover:bg-indigo-50 transition-colors"
                  >
                    {devices.camera && devices.mic ? 'Recheck Permissions' : 'Enable Permissions'}
                  </button>
                </div>

                {/* Device Check Panels */}
                <div className="space-y-4">
                  <div className="p-5 bg-gray-50 rounded-xl border border-gray-200">
                    <div className="flex items-center justify-between mb-3">
                      <div className="flex items-center gap-2">
                        <FiVideo className="w-5 h-5 text-indigo-600" />
                        <h3 className="font-medium text-gray-800">Camera</h3>
                      </div>
                      <span className={`text-sm font-medium ${devices.camera ? 'text-indigo-600' : 'text-gray-500'}`}>
                        {devices.camera ? 'Enabled' : 'Not Detected'}
                      </span>
                    </div>
                    <div className="relative rounded-xl overflow-hidden bg-gray-100 aspect-video border border-gray-200">
                      <video ref={videoRef} autoPlay playsInline muted className="object-cover w-full h-full" />
                      {!devices.camera && (
                        <div className="absolute inset-0 flex items-center justify-center text-gray-500 bg-white bg-opacity-70">
                          <FiX className="w-8 h-8" />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="p-5 bg-gray-50 rounded-xl border border-gray-200">
                    <div className="flex items-center justify-between mb-3">
                      <div className="flex items-center gap-2">
                        <FiMic className="w-5 h-5 text-indigo-600" />
                        <h3 className="font-medium text-gray-800">Microphone</h3>
                      </div>
                      <span className={`text-sm font-medium ${devices.mic ? 'text-indigo-600' : 'text-gray-500'}`}>
                        {devices.mic ? 'Enabled' : 'Not Detected'}
                      </span>
                    </div>
                    <div className="relative bg-gray-100 rounded-xl aspect-square border border-gray-20 h-[200px] w-full">
                      <canvas id="micLevel" className="w-full h-full p-4 mt-4" />
                      {!devices.mic && (
                        <div className="absolute inset-0 flex items-center justify-center text-gray-500 bg-white bg-opacity-70">
                          <FiX className="w-8 h-8" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex gap-3">
                <button
                  onClick={handleBack}
                  className="flex-1 py-3 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-100 transition-colors"
                >
                  Back
                </button>
                <button
                  onClick={handleNext}
                  disabled={!devices.camera || !devices.mic || isLoading}
                  className="flex-1 py-3 rounded-lg bg-indigo-600 hover:bg-indigo-700 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <span className="flex items-center justify-center gap-2">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Checking...
                    </span>
                  ) : (
                    'Continue'
                  )}
                </button>
              </div>
            </div>
          )}

          {currentStep === 'ready' && (
            <div className="space-y-6 text-center">
              <div className="inline-flex items-center justify-center w-24 h-24 mx-auto bg-indigo-50 rounded-full">
                <div className="p-5 bg-indigo-600 text-white rounded-full">
                  <FiCheckCircle className="w-8 h-8" />
                </div>
              </div>

              <div className="space-y-2">
                <h3 className="text-2xl font-semibold text-gray-800">All Set!</h3>
                <p className="text-gray-700 text-sm">
                  You’re ready to launch your interview session.
                </p>
              </div>

              <button
                onClick={startInterview}
                className="w-full py-3 mt-4 rounded-full bg-indigo-600 hover:bg-indigo-700 text-white font-medium transition-colors shadow-sm"
              >
                Launch Interview
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
