// src/screens/OnboardingStep2.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { create_talentHub_interviewer, updateOnboardingStep } from '../../Services/ApiEnpoints';
import { setOnboardingStep } from '../../Redux/Slices/userSlice';
import { BsArrowLeft, BsQuestionCircle } from 'react-icons/bs';
import { MdMic, MdVideocam } from 'react-icons/md';
import { motion, AnimatePresence } from "framer-motion";
import NavBar from './components/NavBar';
import Stepper from './components/Stepper';
import InterviewGuidelModal from './components/InterviewGuidelModal';
import { settoastDetails } from '../../Redux/Slices/toastSlice';
import { fetchProfileData } from '../../Redux/Slices/profileSlice';

const PermissionStatus = ({ label, icon, isGranted }) => (
  <motion.div
    className="flex items-center justify-between p-4 bg-white border border-gray-100 rounded-xl shadow-sm"
    whileHover={{ scale: 1.02 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex items-center gap-3">
      <span className="text-indigo-500">{icon}</span>
      <span className="text-sm font-medium text-gray-700">{label}</span>
    </div>
    <span className={`text-xs font-semibold px-3 py-1 rounded-full ${isGranted ? "bg-green-50 text-green-600" : "bg-red-50 text-red-600"}`}>
      {isGranted ? "Allowed" : "Blocked"}
    </span>
  </motion.div>
);

const OnboardingStep3 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { email } = user || {};
  const [startInterviewLoading, setStartInterviewLoading] = useState(false);
  const [skipInterviewLoading, setSkipInterviewLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [permissions, setPermissions] = useState({
    camera: false,
    microphone: false,
  });
  const [permissionError, setPermissionError] = useState("");
  const [videoStream, setVideoStream] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
      videoRef.current.play().catch((err) => console.error("Video play error:", err));
    }
  }, [videoStream]);

  useEffect(() => {
    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [videoStream]);

  const requestPermissions = async () => {
    setPermissionError("");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setPermissions({ camera: true, microphone: true });
      setVideoStream(stream);
    } catch (err) {
      console.error("Permission Error:", err);
      dispatch(settoastDetails({
        uniqueId: '',
        toaststate: true,
        message: { title: "Error", description: "Permission Error. Please try again." },
        icon: 'error',
      }));
      setPermissionError("Please grant camera and microphone permissions to continue.");
      setPermissions({ camera: false, microphone: false });
      setVideoStream(null);
    }
  };

  const handleStartInterview = async () => {
    setIsModalOpen(false);
    if (!permissions.camera || !permissions.microphone) {
      dispatch(settoastDetails({
        uniqueId: '',
        toaststate: true,
        message: {
          title: "Permissions Required",
          description: "Please enable camera and microphone access to start the interview."
        },
        icon: 'error',
      }));
      return;
    }

    setStartInterviewLoading(true);

    try {
      await updateOnboardingStep("4", email);
      dispatch(setOnboardingStep("4"));

      const requestId = `req_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
      const currentRoute = window.location.pathname;

      const response = await create_talentHub_interviewer();

      navigate("/ai-video-interview", {
        state: {
          interviewResponse: response,
          requestId,
          previousRoute: currentRoute,
          additionalState: { userEmail: email, onboardingStep: "3" }
        }
      });
    } catch (err) {
      console.error("Failed to start interview:", err);
      const errorTitle = err?.response?.data?.title || "Error";
      const errorDescription = err?.response?.data?.description || err.message || "An unexpected error occurred. Please try again.";
      dispatch(settoastDetails({
        uniqueId: '',
        toaststate: true,
        message: { title: errorTitle, description: errorDescription },
        icon: 'error',
      }));
    } finally {
      setStartInterviewLoading(false);
    }
  };

  const handleSkipFunction = async () => {
    setSkipInterviewLoading(true);
    try {
      await updateOnboardingStep("4", email);
      dispatch(setOnboardingStep("4"));
      if (videoStream) videoStream.getTracks().forEach(track => track.stop());
      dispatch(fetchProfileData());
      navigate("/dashboard", {
        state: { userRegistered: true },
      });
    } catch (err) {
      console.error("Failed to update step or navigate:", err);
    } finally {
      setSkipInterviewLoading(false);
    }
  };

  return (
    <>
      <div className="min-h-screen bg-white overflow-auto">
        <NavBar
          links={[
            { label: <span><BsQuestionCircle className="inline mr-1" /> Help</span>, onClick: () => { } },
            { label: "Log in", onClick: () => { } }
          ]}
        />
        <div className="-mt-5">
          <Stepper currentStep={2} />
        </div>

        <main className="flex flex-col items-center justify-center px-4 sm:px-6 py-10 -mt-5">
          {/* Reduced max width and padding for a smaller box */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full max-w-6xl bg-white rounded-[28px] shadow-2xl shadow-gray-300/30 border border-gray-300/60 hover:shadow-2xl hover:shadow-gray-300/60 px-6 py-8"
          >
            <header className="mb-8">
              <motion.button
                whileHover={{ x: -3 }}
                onClick={() => navigate("/signup/onboarding-step2")}
                className="flex items-center gap-2 text-gray-500 text-sm mb-6 hover:text-gray-700 group"
              >
                <BsArrowLeft className="transition-transform group-hover:-translate-x-1" />
                Back
              </motion.button>
              <div className="space-y-2">
                <h1 className="text-2xl font-semibold text-gray-900">
                  Take a 15min AI mock interview with <span className="text-indigo-600">Buddy</span>
                </h1>
                <p className="text-gray-500 text-sm max-w-3xl">
                  This interview aims to evaluate your skills and expertise based on your resume and past experiences.
                </p>
              </div>
            </header>

            <div className="flex flex-col lg:flex-row gap-8">
              <div className="flex-1 bg-gradient-to-br from-gray-900 to-indigo-900 rounded-2xl overflow-hidden aspect-video relative border-2 border-white/10 shadow-xl">
                <AnimatePresence>
                  {videoStream ? (
                    <motion.video
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      ref={videoRef}
                      className="w-full h-full object-cover transform scale-x-[-1]"
                      muted
                      playsInline
                    />
                  ) : (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="absolute inset-0 flex items-center justify-center text-center p-8 bg-gradient-to-br from-gray-800 to-indigo-900"
                    >
                      <div className="space-y-4">
                        <div className="inline-flex bg-white/5 p-4 rounded-2xl border border-white/10">
                          <MdVideocam className="w-8 h-8 text-white/40" />
                        </div>
                        <p className="font-medium text-white/90">Camera Access Required</p>
                        <p className="text-sm text-white/60">
                          Enable camera to continue with your interview setup
                        </p>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="w-full lg:w-[360px] space-y-8">
                <div className="space-y-2">
                  <h2 className="text-xl font-semibold text-gray-900">Ready to join?</h2>
                  <p className="text-sm text-gray-500">
                    Meet <span className="font-medium text-indigo-600">Buddy</span> in the call
                  </p>
                </div>

                <div className="space-y-4">
                  <PermissionStatus
                    label="Microphone"
                    icon={<MdMic className="w-5 h-5" />}
                    isGranted={permissions.microphone}
                  />
                  <PermissionStatus
                    label="Camera"
                    icon={<MdVideocam className="w-5 h-5" />}
                    isGranted={permissions.camera}
                  />
                  {permissionError && (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-sm text-red-500 mt-2 px-4 py-2 bg-red-50 rounded-lg"
                    >
                      {permissionError}
                    </motion.p>
                  )}
                </div>

                <div className="space-y-4 pt-2">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={requestPermissions}
                    className="w-full py-3.5 px-6 rounded-xl bg-indigo-50 text-indigo-600 font-medium text-sm hover:bg-indigo-100 transition-all"
                  >
                    Enable Camera and Audio
                  </motion.button>
                  <motion.button
                    onClick={() => setIsModalOpen(true)}
                    disabled={startInterviewLoading || !permissions.camera || !permissions.microphone}
                    className={`w-full py-3.5 px-6 rounded-xl font-medium text-sm text-white flex justify-center items-center transition-all ${permissions.camera && permissions.microphone
                      ? "bg-indigo-600 hover:bg-indigo-700 shadow-lg hover:shadow-indigo-200"
                      : "bg-gray-200 text-gray-400 cursor-not-allowed"
                      }`}
                  >
                    {startInterviewLoading ? (
                      <div className="w-5 h-5 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
                    ) : (
                      "Start Interview"
                    )}
                  </motion.button>

                  <motion.button
                    onClick={handleSkipFunction}
                    disabled={skipInterviewLoading}
                    className="w-full py-3.5 px-6 rounded-xl text-sm text-gray-600 font-medium hover:bg-gray-50 transition-all flex justify-center items-center"
                  >
                    {skipInterviewLoading ? (
                      <div className="w-5 h-5 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
                    ) : (
                      "Take Interview Later"
                    )}
                  </motion.button>
                </div>
              </div>
            </div>
          </motion.div>
        </main>
      </div>

      {isModalOpen && (
        <InterviewGuidelModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onStart={handleStartInterview}
        />
      )}
    </>
  );
};

export default OnboardingStep3;
