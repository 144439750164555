import React from 'react';
import asendia_logo from '../../../assests/Images/asendia_logo.png'; // adjust the path as needed

const NavBar = ({ links = [] }) => {
  return (
    <nav className="w-full flex items-center justify-between px-4 sm:px-6 py-3 bg-white/0  sticky top-0 z-50">
      <div className="flex items-center">
        <img src={asendia_logo} alt="Logo" className="h-16 w-auto" />
      </div>
      <div className="flex items-center space-x-4">
        {links.map((link, index) => (
          <button 
            key={index} 
            onClick={link.onClick} 
            className="text-sm text-gray-600 hover:text-gray-800 transition-colors"
          >
            {link.label}
          </button>
        ))}
      </div>
    </nav>
  );
};

export default NavBar;
