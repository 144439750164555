import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Removed Prompt and useBlocker
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import { MdCall } from "react-icons/md";
import { RetellWebClient } from "retell-client-js-sdk";
import { BlockBlobClient } from "@azure/storage-blob";
import { useDispatch } from "react-redux";
import { settoastDetails } from "../../../../../Redux/Slices/toastSlice";
import faanglogoName from "../../../../../assests/Images/asendia_logo_text_white.png";
import asendia_logo from "../../../../../assests/Images/asendia_logo.png";
import { BsExclamationCircle } from "react-icons/bs";
import { IoReload } from "react-icons/io5";
import { fetchProfileData } from "../../../../../Redux/Slices/profileSlice";

// ------------------ Simple Loading Overlay ----------------------
const LoadingOverlay = ({ error, message }) => (
  <div className="fixed inset-0 bg-[#131313]/90 backdrop-blur-sm flex items-center justify-center z-50">
    <div className="text-center text-white flex flex-col items-center justify-center space-y-4">
      {error ? (
        <div className="flex flex-col items-center space-y-4 px-4">
          <div className="p-3 bg-red-500/10 rounded-full">
            <BsExclamationCircle className="w-10 h-10 text-red-400" strokeWidth={1.5} />
          </div>
          <div className="space-y-2">
            <h3 className="text-xl font-medium text-red-100">{error.message}</h3>
            <p className="text-gray-300/90 max-w-md text-balance">{error.details}</p>
          </div>
          <button
            onClick={error.onRetry}
            className="mt-2 px-5 py-2.5 bg-white/5 hover:bg-white/10 border border-white/10 rounded-lg backdrop-blur-sm transition-all duration-300 flex items-center gap-2 group"
          >
            <IoReload className="w-5 h-5 text-white animate-spin" />
            <span className="font-medium">Retry Connection</span>
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-4">
          <div className="relative flex items-center justify-center">
            <div className="absolute w-16 h-16 border-2 border-white/10 rounded-full"></div>
            <div className="h-12 w-12 border-4 border-transparent border-t-blue-400 border-r-blue-400 rounded-full animate-spin"></div>
          </div>
          <div className="space-y-2">
            <h3 className="text-xl font-light tracking-wide text-gray-200 animate-pulse">
              {message || "Processing Request"}
            </h3>
            <p className="text-sm text-gray-400/90 font-light">
              This may take a few moments
            </p>
          </div>
        </div>
      )}
    </div>
  </div>
);

// ------------------ Media Error Screen ----------------------
const MediaErrorScreen = ({ errorMessage, onRetry }) => (
  <div className="flex flex-col items-center justify-center h-screen bg-gray-900">
    <div className="text-white text-xl mb-4">{errorMessage}</div>
    <button onClick={onRetry} className="bg-indigo-600 px-4 py-2 rounded text-white">
      Retry
    </button>
  </div>
);

const TalenthubVideoInterview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ------------------ Props from location.state ----------------------
  const { interviewResponse } = location.state || {};
  const access_token = interviewResponse?.access_token;
  const user_id = interviewResponse?.user_id;
  const call_id = interviewResponse?.call_id;
  const profileId = interviewResponse?.profile_id || "default_profile";
  const { previousRoute } = location.state || {};

  // ------------------ State Variables ----------------------
  const [elapsedTime, setElapsedTime] = useState(0);
  const [mediaError, setMediaError] = useState(null);
  const [isCallActive, setIsCallActive] = useState(false);
  const [isMicMuted, setIsMicMuted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [mediaReady, setMediaReady] = useState(false);
  const [processingMessage, setProcessingMessage] = useState("");
  const [showEndCallModal, setShowEndCallModal] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isAgentTalking, setIsAgentTalking] = useState(false);

  // ------------------ References ----------------------
  const videoRef = useRef(null);
  const retellWebClientRef = useRef(null);

  // MediaRecorder + Azure block references
  const mediaRecorderRef = useRef(null);
  const blockBlobClientRef = useRef(null);
  const blockIdsRef = useRef([]); // holds the list of staged block IDs
  const filenameRef = useRef(null); // store the filename used for this recording

  // The user/media streams
  const micStreamRef = useRef(null);

  // We keep an AudioContext ref in case we need it for custom audio handling
  const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());

  // ------------------ Keep some state in a ref for unload handlers ----------------------
  const stateRef = useRef();
  useEffect(() => {
    stateRef.current = { 
      isCallActive, 
      uploadComplete,
      blockIds: blockIdsRef.current,
      blockBlobClient: blockBlobClientRef.current
    };
  }, [isCallActive, uploadComplete]);

  const stopAllMedia = () => {
    try {
      if (mediaRecorderRef.current?.state === "recording") {
        mediaRecorderRef.current.stop();
      }
      retellWebClientRef.current?.stopCall();
      if (videoRef.current?.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        videoRef.current.srcObject = null;
      }
    } catch (error) {
      console.error("Error stopping media:", error);
    }
  };

  // ------------------ Call Duration Timer ----------------------
  useEffect(() => {
    let interval;
    if (isCallActive) {
      interval = setInterval(() => {
        setElapsedTime((prev) => prev + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isCallActive]);

  // ------------------ Setup Retell Web Client ----------------------
  useEffect(() => {
    retellWebClientRef.current = new RetellWebClient();
    return () => {
      retellWebClientRef.current?.stopCall();
    };
  }, []);

  // ------------------ Stop Media on Page Unload or Navigation ----------------------
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (stateRef.current.isCallActive && !stateRef.current.uploadComplete) {
        event.preventDefault();
        event.returnValue = "";
        stopAllMedia();
        
        // Final upload attempt (asynchronously; note that browser limitations may apply)
        if (stateRef.current.blockBlobClient && stateRef.current.blockIds.length > 0) {
          stateRef.current.blockBlobClient.commitBlockList(stateRef.current.blockIds)
            .catch(err => console.error("Final upload attempt failed:", err));
        }
      }
    };

    const handlePopState = () => {
      if (stateRef.current.isCallActive && !stateRef.current.uploadComplete) {
        stopAllMedia();
        navigate(location.pathname, { replace: true });
      }
    };

    // Handle tab closing (when the document becomes hidden)
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden" && stateRef.current.isCallActive) {
        stopAllMedia();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [navigate, location.pathname]);

  // NOTE:
  // In-app navigation blocking (the equivalent of <Prompt>) is not implemented here because
  // React Router’s blocking hooks (such as useBlocker or unstable_usePrompt) require a data router.
  // If you wish to block in-app navigation, consider switching to a data router (e.g. using createBrowserRouter
  // with RouterProvider) or implement your own custom logic on your link components.

  // ------------------ Request Camera + Mic Access ----------------------
  const requestMediaAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          frameRate: { ideal: 30, max: 30 },
          facingMode: "user",
        },
        audio: true,
      });

      if (audioContextRef.current.state === "suspended") {
        await audioContextRef.current.resume();
      }

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.oncanplay = () => {
          videoRef.current
            .play()
            .then(() => setMediaReady(true))
            .catch((err) => console.error("Video play error:", err));
        };
      }
      micStreamRef.current = stream;
    } catch (error) {
      console.error("Error accessing media:", error);
      setMediaError("Unable to access camera and microphone. Please check permissions and try again.");
    }
  };

  useEffect(() => {
    if (access_token) {
      requestMediaAccess();
    }
  }, [access_token]);

  // ------------------ Helpers: Get SAS token + Trigger Processing ----------------------
  const getSASToken = async (filename) => {
    try {
      const response = await fetch("https://hr-tech-demo-backend.blackpebble-3ec2902f.westeurope.azurecontainerapps.io/api/get-sas-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ user_id, profile_id: profileId, filename }),
      });
      if (!response.ok) throw new Error("Failed to get SAS token");
      return response.json();
    } catch (err) {
      console.error("Error getting SAS token:", err);
      dispatch(
        settoastDetails({
          uniqueId: "",
          toaststate: true,
          message: { title: "Error", description: "Failed to get SAS token. Please try again." },
          icon: "error",
        })
      );
      throw err;
    }
  };

  const triggerProcessing = async (filename) => {
    try {
      const response = await fetch("https://hr-tech-demo-backend.blackpebble-3ec2902f.westeurope.azurecontainerapps.io/api/trigger-processing", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id, profile_id: profileId, filename,call_id:call_id }),
      });
      if (!response.ok) {
        console.error("Failed to trigger processing");
      }
    } catch (e) {
      console.error("Error triggering processing:", e);
    }
  };

  // ------------------ The Chunked Recording Approach ----------------------
  const startRecording = async () => {
    try {
      // 1) Prepare the final combined stream (video + audio).
      const stream = videoRef.current.srcObject;
      const finalStream = new MediaStream([
        ...stream.getVideoTracks(),
        ...stream.getAudioTracks(),
      ]);

      // 2) Generate a unique filename & get SAS token
      const filename = `video_${Date.now()}.webm`;
      filenameRef.current = filename;

      setProcessingMessage("Initializing blob client...");
      const { sas_url } = await getSASToken(filename);
      blockBlobClientRef.current = new BlockBlobClient(sas_url);
      blockIdsRef.current = [];

      // 3) Create the MediaRecorder
      const options = { mimeType: "video/webm; codecs=vp8,opus" };
      const mediaRecorder = new MediaRecorder(finalStream, options);
      mediaRecorderRef.current = mediaRecorder;

      // 4) Handle chunk staging each time `ondataavailable` fires
      mediaRecorder.ondataavailable = async (event) => {
        if (event.data && event.data.size > 0) {
          try {
            // Create a unique block ID (must be base64-encoded)
            const blockId = btoa(`block-${crypto.randomUUID()}`);
            // Stage this chunk to Azure (uncommitted block)
            await blockBlobClientRef.current.stageBlock(blockId, event.data, event.data.size);
            blockIdsRef.current.push(blockId);
          } catch (err) {
            console.error("Error staging block:", err);
          }
        }
      };

      // 5) On stop, commit all staged blocks to finalize the blob
      mediaRecorder.onstop = async () => {
        setProcessingMessage("Finalizing upload...");
        try {
          await blockBlobClientRef.current.commitBlockList(blockIdsRef.current, {
            blobHTTPHeaders: { blobContentType: "video/webm" },
          });
          console.log("All chunks committed. Blob upload complete!");
          await triggerProcessing(filenameRef.current);
          setProcessingMessage("");
          setUploadComplete(true);
          setIsAgentTalking(false);
          // Clear location.state after the call ends
          navigate(location.pathname, { replace: true, state: {} });
        } catch (err) {
          console.error("Error committing block list:", err);
          setProcessingMessage("Error finalizing the video upload.");
          dispatch(
            settoastDetails({
              uniqueId: "",
              toaststate: true,
              message: { title: "Error", description: "Failed to upload video. Please try again." },
              icon: "error",
            })
          );
        }
      };

      // 6) Start the recording with a timeslice for frequent chunk uploads (e.g., 1000 ms)
      mediaRecorder.start(1000);
      setIsCallActive(true);
      setProcessingMessage("");
    } catch (error) {
      console.error("Error starting recording:", error);
      setProcessingMessage("Error initializing recording.");
    }
  };

  // Exposed function to stop recording (will trigger onstop)
  const stopRecording = () => {
    if (mediaRecorderRef.current?.state === "recording") {
      mediaRecorderRef.current.stop();
    }
  };

  // ------------------ Retell Events (call start/end, talking events) ----------------------
  useEffect(() => {
    if (!mediaReady || !access_token) return;
    const client = retellWebClientRef.current;

    client
      .startCall({
        accessToken: access_token,
        sampleRate: 24000,
        captureDeviceId: "default",
        playbackDeviceId: "default",
        emitRawAudioSamples: true,
      })
      .catch((error) => console.error("Error starting Retell call:", error));

    client.on("call_started", () => {
      console.log("call started");
      startRecording();
    });

    client.on("call_ended", () => {
      console.log("call ended");
      setIsCallActive(false);
      setCallEnded(true);
      stopRecording();

      // Stop the video stream
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        stream.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
    });

    client.on("agent_start_talking", () => {
      console.log("agent_start_talking");
      setIsAgentTalking(true);
    });

    client.on("agent_stop_talking", () => {
      console.log("agent_stop_talking");
      setIsAgentTalking(false);
    });

    client.on("update", (update) => {
      console.log(update.transcript);
    });

    client.on("error", (error) => {
      console.error("An error occurred:", error);
      dispatch(
        settoastDetails({
          uniqueId: "",
          toaststate: true,
          message: { title: "Error", description: "An error occurred during the call. Please try again." },
          icon: "error",
        })
      );
      client.stopCall();
    });

    return () => {
      client.off("call_started");
      client.off("call_ended");
      client.off("agent_start_talking");
      client.off("agent_stop_talking");
      client.off("update");
      client.off("error");
    };
  }, [mediaReady, access_token, dispatch]);

  // ------------------ Mute / Unmute Logic ----------------------
  const toggleMute = () => {
    if (!retellWebClientRef.current) return;
    if (isMicMuted) {
      retellWebClientRef.current.unmute();
    } else {
      retellWebClientRef.current.mute();
    }
    setIsMicMuted(!isMicMuted);
  };

  // ------------------ End Call / Confirmation ----------------------
  const handleEndCallClick = () => {
    setShowEndCallModal(true);
  };

  const confirmEndCall = () => {
    setShowEndCallModal(false);
    retellWebClientRef.current?.stopCall();
  };

  const cancelEndCall = () => {
    setShowEndCallModal(false);
  };

  // ------------------ Handle navigation after call ended ----------------------
  const handleBackNavigation = () => {
    stopAllMedia();
    
    if (previousRoute === "/signup/onboarding-step2") {
      navigate("/signup/onboarding-step3");
    } else {
      navigate("/dashboard", { state: { userRegistered: true } });
      dispatch(fetchProfileData());
    }
  };
  

  // ------------------ Format call duration ----------------------
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  // ------------------ Render ----------------------
  if (!access_token && !uploadComplete) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-center p-8">
          <h2 className="text-xl font-bold mb-4 text-red-600">Session Error</h2>
          <p className="text-gray-600">Please restart the interview from the beginning.</p>
        </div>
      </div>
    );
  }

  if (mediaError) {
    return <MediaErrorScreen errorMessage={mediaError} onRetry={requestMediaAccess} />;
  }

  return (
    <div className="bg-black/90 fixed inset-0 flex flex-col">
      {/* Note: In-app navigation blocking (like a <Prompt> component) has been removed.
          Native browser events will warn the user if they try to refresh or close the tab. */}

      {/* Loading Overlay if call hasn't started or is processing */}
      {(!isCallActive && !callEnded) && (
        <LoadingOverlay message="Initializing Interview..." />
      )}
      {(processingMessage && callEnded) && (
        <LoadingOverlay message={processingMessage} />
      )}

      {/* Top Bar */}
      <div className="flex items-center h-16 px-5 border-b border-gray-800">
        <img className="h-6" src={faanglogoName} alt="Company Logo" />
        <div className="ml-auto bg-gray-800 text-white rounded-full px-4 py-1">
          Interview Session
        </div>
      </div>

      {/* Main content area */}
      <div className="flex flex-1 overflow-hidden p-2 gap-2">
        {/* Video Container */}
        <div className="relative flex-1 bg-[#131313] rounded-xl overflow-hidden">
          <div className="absolute top-4 right-4 z-10 bg-black/30 backdrop-blur-sm px-4 py-1 rounded-full text-white flex items-center gap-2">
            <span className="w-3 h-3 bg-red-500 rounded-full animate-pulse"></span>
            <span>Recording • {formatTime(elapsedTime)}</span>
          </div>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            className="h-full w-full object-cover transform -scale-x-100"
          />
        </div>

        {/* Avatar / Info */}
        <div className="w-1/4 ml-4 h-full flex flex-col items-center justify-center bg-[#1d1d1d] rounded-2xl">
          <div
            className={`relative w-44 h-44 rounded-full overflow-hidden shadow-2xl border-4 transition-transform duration-300 ${
              isAgentTalking ? "border-indigo-500 animate-pulse" : "border-white/40"
            }`}
            style={{
              background: "rgba(255, 255, 255, 0.17)",
              backdropFilter: "blur(20px)",
              WebkitBackdropFilter: "blur(20px)",
              boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.2), inset 0px 2px 6px rgba(255, 255, 255, 0.15)",
            }}
          >
            <div className="absolute inset-0 rounded-full border border-white/10 shadow-lg blur-md opacity-40"></div>
            <div className="absolute inset-0 bg-gradient-to-br from-white/10 via-white/5 to-transparent backdrop-blur-3xl rounded-full"></div>
            <div className="relative flex items-center justify-center w-full h-full">
              <img
                src={asendia_logo}
                alt="User Avatar"
                className="w-full h-full object-cover rounded-full transform scale-[0.92] hover:scale-[0.98] transition-all duration-300 ease-out"
              />
            </div>
          </div>
          <h3 className="mt-4 text-[18px] text-white/70 font-normal tracking-wide">
            Asendia Interviewer
          </h3>
        </div>
      </div>

      {/* Bottom Controls */}
      <div className="h-20 flex items-center justify-between px-8 border-t border-gray-800">
        <div className="text-gray-400">
          {new Date().toLocaleTimeString()} | AI Assessment Interview
        </div>
        <div className="flex gap-6">
          {/* End Call */}
          <div className="flex flex-col items-center">
            <button
              onClick={handleEndCallClick}
              className="w-12 h-12 bg-red-600 rounded-full flex items-center justify-center hover:bg-red-500 transition-colors"
            >
              <MdCall className="text-white text-xl" />
            </button>
            <span className="text-xs text-gray-400 mt-1">Leave</span>
          </div>
          {/* Mute / Unmute */}
          <div className="flex flex-col items-center">
            <button
              onClick={toggleMute}
              className="w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center hover:bg-gray-700 transition-colors"
            >
              {isMicMuted ? (
                <IoMdMicOff className="text-gray-400 text-xl" />
              ) : (
                <IoMdMic className="text-gray-400 text-xl" />
              )}
            </button>
            <span className="text-xs text-gray-400 mt-1">
              {isMicMuted ? "Unmute" : "Mute"}
            </span>
          </div>
        </div>
      </div>

      {/* End Call Confirmation Modal */}
      {showEndCallModal && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="relative w-full max-w-lg p-9 bg-white/70 border border-white/20 shadow-2xl rounded-3xl transition-transform transform scale-95 animate-fadeInUp">
            <div className="text-center">
              <h2 className="text-3xl font-semibold text-gray-800/90 mb-4">
                Are You Sure?
              </h2>
              <p className="text-gray-700 text-lg mb-6">
                Your call is still active. If you leave now, you won’t be able to retake it.
              </p>
            </div>
            <div className="flex justify-center gap-6">
              <button
                onClick={confirmEndCall}
                className="px-8 py-4 text-lg font-semibold text-white bg-red-600 rounded-xl shadow-xl hover:bg-red-500 transition-all duration-300"
              >
                Yes, End Call
              </button>
              <button
                onClick={cancelEndCall}
                className="px-8 py-4 text-lg font-semibold text-gray-900 bg-gray-300 rounded-xl shadow-lg hover:bg-gray-400 transition-all duration-300"
              >
                Cancel
              </button>
            </div>
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-32 h-32 bg-white/10 blur-3xl opacity-40"></div>
          </div>
        </div>
      )}

      {/* Overlay after call ends */}
      {callEnded && !uploadComplete && (
        <LoadingOverlay message="Submitting your interview..." />
      )}

      {callEnded && uploadComplete && (
        <div className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-50">
          <div className="relative w-full max-w-lg p-10 bg-white/90 border border-white/20 shadow-2xl rounded-3xl transition-transform transform scale-95 animate-fadeInUp">
            <div className="flex justify-center mb-6">
              <div className="w-20 h-20 bg-indigo-500/20 border border-indigo-500/30 flex items-center justify-center rounded-full shadow-lg">
                <svg className="w-12 h-12 text-indigo-400" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              </div>
            </div>
            <div className="text-center">
              <h2 className="text-3xl font-semibold text-gray-800/90 mb-3">
                Interview Completed!
              </h2>
              <p className="text-gray-700 text-lg mb-6">
                Your session lasted{" "}
                <strong className="text-gray-900">{formatTime(elapsedTime)}</strong>.
                You can now proceed to the next steps.
              </p>
            </div>
            <div className="flex justify-center">
              <button
                onClick={handleBackNavigation}
                className="px-8 py-4 text-lg font-semibold text-white bg-indigo-600 rounded-full shadow-lg hover:bg-indigo-500 transition-all duration-300"
              >
                Continue
              </button>
            </div>
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-32 h-32 bg-white/10 blur-3xl opacity-40"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TalenthubVideoInterview;
